import { NavigationGuardNext, Route } from 'vue-router';
import { UserRole } from '@/core';
import { UserModel, UserRule } from '@/core/enums';
import store, { UserInfo } from '../../store';

export function canEnter(to: Route, from: Route, next: NavigationGuardNext) {
  const { rules, role, organizationType } = store.state.Shared.userInfo as UserInfo;

  if (role === UserRole.ROOT) return next();
  if (role === UserRole.USER && organizationType === '5pl') return next();

  if (!to.meta || !to.meta.model || !to.meta.rule) return false;

  const { model, rule } = to.meta as Meta;

  if (rules.find(r => r.model === model && r.rule === rule)) {
    return next();
  }

  return false;
}

interface Meta {
  model: UserModel;
  rule: UserRule;
}
