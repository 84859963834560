import { Component, Prop, Watch } from 'vue-property-decorator';
import { SharedVehicleService, VehicleItem, VehicleService } from '@/services';
import { FormControlClass, VuetifySelectItem } from '@/core';
import { namespace } from 'vuex-class';
import { FindAllOptions, ScheduleFilter } from '@/services/sharedVehicle/interface';
import DocumentsDialog from '@/pages/TripsPage/components/TripDialog/components/DocumentsDialog/DocumentsDialog.vue';

const vehicleStore = namespace('Vehicle');

@Component({
  components: { DocumentsDialog },
})
export default class VehicleSelect extends FormControlClass<string> {
  @vehicleStore.Mutation
  protected readonly setVehicles!: (vehicles: VehicleItem[]) => void;

  @Prop({ default: false })
  private readonly for2pl!: boolean;

  @Prop({ default: false })
  private readonly isFleetOwner!: boolean;

  @Prop()
  private readonly partnerId!: string | undefined;

  @Prop({ default: false })
  private readonly isPartnerRequired!: boolean;

  @Prop()
  private readonly scheduleFilter: ScheduleFilter | undefined;

  @Prop({ default: false })
  private readonly showType!: boolean;

  @Prop({ default: false })
  private readonly showExpireService!: boolean;

  @Prop({ default: () => [] })
  private readonly excludeVehiclesIds!: string[];

  @Prop({ default: false })
  private readonly checkOnline!: boolean;

  private isSelectedAll = false;

  public limit = 20;

  public search = ''

  @Watch('search')
  public async searchQuery() {
    if (this.search.length >= 3) {
      await this.setData(this.search);
    }
  }

  public async setData(searchQuery?: string): Promise<void> {
    if (this.isPartnerRequired && !this.partnerId) return;

    try {
      this.isLoading = true;

      let vehicles: VehicleItem[];
      if (this.isFleetOwner) {
        const { data } = await VehicleService.findAll({ page: 1, limit: this.limit });
        vehicles = data;
      } else {
        const options: FindAllOptions = {
          page: 1,
          limit: this.limit,
          search: searchQuery,
        };

        if (!this.for2pl && this.partnerId) {
          options.partnerId = this.partnerId;
        }

        if (this.scheduleFilter) {
          options.scheduleFilter = this.scheduleFilter;
        }

        if (this.for2pl && this.partnerId) {
          options.partnerId = this.partnerId;
          options.isPl2 = true;
        }
        const { data } = await SharedVehicleService.findAllAvailable(options, true);
        if (this.customFilter !== undefined) {
          vehicles = data.filter(this.customFilter).map(v => v.vehicle);
        }

        vehicles = data.map(v => v.vehicle);
      }
      this.setVehicles(vehicles);

      this.items = vehicles.map((v) => {
        let text = this.showType ? `${v.registrationPlate} / ${v.type.name}` : v.registrationPlate;
        if (this.showExpireService) {
          text += ` ${v.serviceIntervalInfo.isExpired ? `/ ${v.serviceIntervalInfo.text}` : ''}`;
        }
        return { value: v.id, text: text.trim() };
      });
    } finally {
      this.isLoading = false;
    }
  }

  public async loadMore() {
    this.limit += 20;
    await this.setData();
  }

  public selectAll(): void {
    this.isSelectedAll = !this.isSelectedAll;
    if (this.isSelectedAll) {
      this.$emit('input', this.items.map((i) => (i as VuetifySelectItem<string>).value));
    } else {
      this.$emit('input', []);
    }
  }

  @Watch('value', { immediate: true })
  private async vehicleSelected(): Promise<void> {
    console.log('value', this.value);
    if (this.checkOnline && this.value) {
      const isOnline = await VehicleService.isOnline(this.value);
      if (!isOnline) {
        (this.items as VuetifySelectItem<string>[]) = (this.items as VuetifySelectItem<string>[]).map(i => {
          if (i.value === this.value) {
            return { ...i, text: !i.text.includes('GPS Fault') ? `${i.text} / GPS Fault` : i.text };
          }

          return i;
        });
      }
    }
  }

  @Watch('excludeVehiclesIds')
  private excludeVehiclesIdsChanged(): void {
    this.items = (this.items as VuetifySelectItem<string>[]).filter(i => !this.excludeVehiclesIds.includes(i.value));
  }
}
