import { HTTP, Pagination, PaginationOptions } from '@/core';
import { Trip, TripShort } from '@/services/trip/interface/tripItem.interface';
import { FindAllOptions } from '@/services/order/interface';
import { StoreTripRequest, TripItem, TripArchiveItemShort, TripArchiveItemFull, TripItemShort } from './interface';
import { TripStage } from './enum';

export class TripService {
  static async store(trip: StoreTripRequest): Promise<TripItem> {
    const { data } = await HTTP.post('trips', trip);

    return data;
  }

  static async findAllV1(
    params: PaginationOptions & { stages?: TripStage[]; excludeStages?: TripStage[] },
  ): Promise<Pagination<TripItemShort>> {
    const { data } = await HTTP.get('v1/trips', { params });

    return data;
  }
  static async findAllV1New(params: FindAllOptions) {
    const { data } = await HTTP.get('v1/trips', { params });

    return data;
  }

  static async findAllV2(params: FindAllOptionsV2) {
    const { data } = await HTTP.get('v2/trips', { params });

    return data;
  }
  static async findOneV2(tripId: string, params: FindAllOptionsV2):Promise<Trip> {
    const { data } = await HTTP.get(`v2/trips/${tripId}`, { params });

    return data;
  }
  static async demmurageTrips():Promise<TripShort[]> {
    const { data } = await HTTP.get('v1/trips/demurrage');

    return data;
  }

  static async findOne(tripId: string): Promise<TripItem> {
    const { data } = await HTTP.get(`trips/${tripId}`);

    return data;
  }

  static async update(tripId: string, trip: Partial<StoreTripRequest>): Promise<TripItem> {
    const { data } = await HTTP.patch(`trips/${tripId}`, trip);

    return data;
  }

  static async updateStage(tripId: string, stage: TripStage): Promise<void> {
    await HTTP.patch(`trips/${tripId}/stage`, { stage });
  }

  static async delete(tripId: string): Promise<void> {
    await HTTP.delete(`trips/${tripId}`);
  }

  static async moveToArchive(id: string): Promise<void> {
    await HTTP.post(`trips/archive/${id}`);
  }

  static async findAllArchive(options: PaginationOptions): Promise<Pagination<TripArchiveItemShort>> {
    const { data } = await HTTP.get<Pagination<TripArchiveItemShort>>('trips/archive', { params: options });

    return data;
  }

  static async findOneArchive(id: string): Promise<TripArchiveItemFull> {
    const { data } = await HTTP.get<TripArchiveItemFull>(`trips/archive/${id}`);

    return data;
  }

  static async getCount(type: 'active' | 'total'): Promise<number> {
    const { data } = await HTTP.get('v1/trips/count', { params: { type } });

    return data.count;
  }

  static async getCountByDate(from: number, to: number): Promise<{ date: string; count: number }[]> {
    const { data } = await HTTP.get('v1/trips/count-by-date', { params: { from, to } });

    return data;
  }
}
export interface FindAllOptionsV2 extends PaginationOptions {
  fields?: string;
  stages?: string;
  to?: number;
  from?: number
}
