export enum ReportName {
  deliveryManifest = 'Delivery Manifest',
  loadingPlan = 'Loading Plan',
  orderDetailReport = 'Order Detail Report',
  tripDetailReport = 'Trip Detail Report',
  tripsInvoice = 'Trips invoice',
  ordersInvoice = 'Orders invoice',
  ordersDeliveryStatus = 'Orders delivery status',
  tripLocationDetailReport = 'Trip location detailed report',
  vehicleComplianceReport = 'Vehicle compliance report',
  pickingSlipReport = 'Picking slip',
  configurableReport = 'Configurable Trip Detail Report',
  ConfigurableOrderDetailReport = 'Configurable Order Detail Report'
}
